import { Image } from '@nextui-org/react';
import styled from 'styled-components';

export const LogoContainer = styled.div`
  display: flex;
`;

export const AppImage = styled(Image)`
  margin: 0;
`;

export const SideBarTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;
