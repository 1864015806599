import React, { useEffect, useMemo, useState } from 'react';

import {
  InfiniteScrollDropdownProps,
  InfiniteScrollDropdownSelection,
} from '~/components/InfiniteScrollDropdown/InfiniteScrollDropdown';
import { AudiencePageQueryAudience } from '~/constants/graphqlTypes';
import { useExtendedTranslation } from '~/hooks/useExtendedTranslation';
import { AudienceCondition, OrganizationType, useAudiencesPageQuery } from '~/types/api.graphql';

import { DropdownItemProps } from '../Dropdown/Dropdown';
import { StyledInfiniteScrollDropdown } from './AudiencesDropdown.styles';

const PAGE_ITEMS_COUNT = 10;

export interface AudiencesDropdownProps
  extends Omit<InfiniteScrollDropdownProps<AudiencePageQueryAudience>, 'onSelectionChange'> {
  organizationType?: OrganizationType;
  isAudienceDisabled?: (audience: AudiencePageQueryAudience) => boolean;
  onSelectionChange?: (selection: InfiniteScrollDropdownSelection, audience: AudiencePageQueryAudience) => void;
  organizationId: string;
  conditions?: AudienceCondition[];
}

const AudiencesDropdown = (props: AudiencesDropdownProps) => {
  const { tSpecific } = useExtendedTranslation();
  const [items, setItems] = useState<DropdownItemProps<AudiencePageQueryAudience>[]>([]);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [searchOrgInfiniteScroll, setSearchOrgInfiniteScroll] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number>(props.selectedIndex);

  const disabledKeys = useMemo(() => {
    return items.filter((item) => item.disabled).map((item) => item.key);
  }, [items]);

  const { refetch, loading: loadingFirst } = useAudiencesPageQuery({
    variables: {
      pageNumber: page,
      pageItemsCount: PAGE_ITEMS_COUNT,
      searchQuery: searchOrgInfiniteScroll,
      organizationId: props.organizationId,
      ...(props.conditions ? { conditions: props.conditions } : {}),
    },
    fetchPolicy: 'network-only',
    skip: page > 1 || !props.organizationId,
    onCompleted: (dataOrgs) => {
      const newItems = [
        ...items,
        ...(dataOrgs?.audiencesPage?.nodes?.map((audience) => ({
          text: audience.name,
          value: audience,
          description: tSpecific(`audience.overview.${audience?.condition?.toLowerCase()}`),
          key: audience.id,
          disabled: props.isAudienceDisabled ? props.isAudienceDisabled(audience) : false,
        })) || []),
      ];
      setItems(newItems);
      setHasMore(dataOrgs?.audiencesPage?.pageInfo?.hasNextPage);
      setPage(page + 1);
    },
  });

  useEffect(() => {
    setPage(1);
    setItems([]);
    setSelectedIndex(props.selectedIndex);
  }, [searchOrgInfiniteScroll, props.organizationId]);

  useEffect(() => {
    if (items?.length === 1) {
      handleItemSelection(0);
    }
  }, [items, page]);

  const handleLoadMore = () => {
    void (async () => {
      setLoadingMore(true);
      await refetch({ pageNumber: page, pageItemsCount: PAGE_ITEMS_COUNT, searchQuery: searchOrgInfiniteScroll });
      setPage(page + 1);
      setLoadingMore(false);
    })();
  };

  const handleItemSelection = (selection) => {
    const selectedIndex = selection as number;
    setSelectedIndex(selectedIndex);
    props.onSelectionChange?.(selectedIndex, items[selectedIndex].value);
  };

  return (
    <StyledInfiniteScrollDropdown
      {...props}
      items={items}
      disabledKeys={disabledKeys}
      onSelectionChange={handleItemSelection}
      selectedIndex={selectedIndex}
      setSearchOrgInfiniteScroll={setSearchOrgInfiniteScroll}
      searchOrgInfiniteScroll={searchOrgInfiniteScroll}
      hasMore={hasMore}
      loadMore={handleLoadMore}
      loadingFirst={loadingFirst}
      loadingMore={loadingMore}
      disallowEmptySelection
    />
  );
};

export default AudiencesDropdown;
