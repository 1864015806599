import { Tooltip } from '@nextui-org/react';
import React, { MouseEvent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

import { ShimmerImageWrapper, ShimmerTextWrapper } from '~/components';
import SupportComponent from '~/components/SupportModalContent/SupportComponent';
import { usePrevious } from '~/hooks/usePrevious';
import {
  CollapseButton,
  CollapseChevron,
  Container,
  DummyTabletContainer,
  ItemContainer,
  ItemsContainer,
  ResizeArea,
  SectionContainer,
  SectionsContainer,
  SectionTitle,
  TopContainer,
} from '~/overwrites/views/layouts/SideBar/SideBar.styles';
import { isSubset } from '~/utils/helpers';
import { useLinkHandler } from '~/utils/RouteHelper';
import { Badge } from '~/views/audiences/CreateAudienceModal/TypeCard/TypeCard.styles';
import { SideBarTitle } from '~/views/layouts/SideBar/Logo.styles';

import { useListFilterContext } from '../../../contexts/ListFiltersContext';
import { SideBarLogo } from './Logo';

export interface SideBarItem {
  title: string;
  icon?: ReactNode;
  disabled?: boolean;
  // if defaultSelected is true we select the item if navigation state is null
  defaultSelected?: boolean;
  loading?: boolean;
  path: string;
  navigateOptions?: NavigateOptions;
  badge?: string;
}

export interface SideBarSection {
  title?: string;
  items: SideBarItem[];
  collapsedProps?: {
    title?: string;
  };
}

export interface SideBarContent {
  name: string;
  sections: SideBarSection[];
}

interface SideBarProps {
  content?: SideBarContent;
  bottomItems?: (collapsed: boolean) => ReactNode;
  collapsed?: boolean;
  onCollapsedStateChange?: (collapsed: boolean) => void;
  animated?: boolean;
}

export const SideBar = ({ content, bottomItems, collapsed, onCollapsedStateChange, animated }: SideBarProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { handleLinkClick } = useLinkHandler();
  const { filter } = useListFilterContext();
  const [collapsedLocal, setCollapsedLocal] = useState(!!collapsed);
  const collapsedLocalOld = usePrevious(collapsedLocal);
  const collapsedOld = usePrevious(collapsed);
  if (collapsed !== undefined && collapsed !== collapsedOld && collapsed !== collapsedLocal) {
    setCollapsedLocal(collapsed);
  }

  const SectionTitleComponent = ({ section }: { section: SideBarSection }) => {
    const title = collapsedLocal ? section.collapsedProps?.title || section.title : section.title;
    return (
      title && (
        <SectionTitle key={title} $animated={collapsedLocalOld !== null && collapsedLocal !== collapsedLocalOld}>
          {title}
        </SectionTitle>
      )
    );
  };

  const isEqualsPath = (item: SideBarItem) => location.pathname.startsWith(item.path);

  const isMoreSuperficialPath = (item: SideBarItem) => {
    const itemPath = item.path;
    const currentPath = location.pathname;
    return itemPath.length < currentPath.length;
  };

  const isActiveItem = (item: SideBarItem) => {
    if (item.loading || !isEqualsPath(item)) return false;

    if (item.navigateOptions?.state) {
      return location.state ? isSubset(location.state, item.navigateOptions.state) : item.defaultSelected;
    }

    return true;
  };

  const handleItemClick = (e: MouseEvent, item: SideBarItem) => {
    if (isActiveItem(item) && !isMoreSuperficialPath(item)) return;

    const filterObject = {
      filterId: filter?.id,
      filterImgSrc: filter?.imgSrc,
      filterName: filter?.text,
      description: t(`organization.settings.orgTypes.${filter?.description?.toLowerCase()}`),
    };
    if (!item.navigateOptions?.state) {
      handleLinkClick(e, item.path, {
        ...item.navigateOptions,
        state: { ...filterObject, ...item.navigateOptions?.state },
      });
      return;
    }

    const isSamePath = isEqualsPath(item);
    navigate(item.path, {
      ...item.navigateOptions,
      state: {
        ...filterObject,
        ...(isSamePath && { ...(location.state as any) }),
        ...item.navigateOptions?.state,
        ...(isSamePath && { disableAnimation: true }),
      },
    });
  };

  const handleCollapsedClick = () => {
    const collapsed = !collapsedLocal;
    setCollapsedLocal(collapsed);
    onCollapsedStateChange?.(collapsed);
  };

  return (
    <>
      <Container $collapsed={collapsedLocal}>
        <TopContainer>
          <SideBarLogo gradientId="sideBar" collapsed={collapsedLocal} />
          <SectionsContainer $animated={animated}>
            {content?.sections?.map((section, index) => (
              <SectionContainer key={index}>
                <SectionTitleComponent section={section} />
                <ItemsContainer>
                  {section.items.map((item, index) => (
                    <ItemContainer
                      key={index}
                      $active={isActiveItem(item)}
                      $disabled={item.disabled}
                      onClick={(e) => handleItemClick(e, item)}
                    >
                      <ShimmerImageWrapper loading={item.loading} width="1.25rem" height="1.25rem">
                        {collapsedLocal ? (
                          <Tooltip content={item.title} placement={'right'} hideArrow portalClassName="tooltip-wrapper">
                            {item.icon}
                          </Tooltip>
                        ) : (
                          item.icon
                        )}
                      </ShimmerImageWrapper>
                      <ShimmerTextWrapper loading={item.loading} width={`${item.title.length * 0.6}rem`}>
                        <SideBarTitle>
                          {item.title}
                          {item.badge ? <Badge>{item.badge}</Badge> : null}
                        </SideBarTitle>
                      </ShimmerTextWrapper>
                    </ItemContainer>
                  ))}
                </ItemsContainer>
              </SectionContainer>
            ))}
          </SectionsContainer>
          <SupportComponent collapsed={collapsedLocal} />
        </TopContainer>
        <div>{bottomItems(collapsedLocal)}</div>
        <ResizeArea>
          <CollapseButton onClick={handleCollapsedClick}>
            <CollapseChevron $collapsed={collapsedLocal} />
          </CollapseButton>
        </ResizeArea>
      </Container>
      <DummyTabletContainer />
    </>
  );
};
