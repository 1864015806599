import { Modal as NextModal } from '@nextui-org/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { inMobile, useWindowDimensions } from '../../hooks/useDimensions';
import { ButtonRole } from '../Button';
import {
  BodyContainer,
  StyledBottomSheet,
  StyledButton,
  StyledFooter,
  StyledModal,
  StyledSecondaryButton,
} from './Modal.styles';
import ModalHeader from './ModalHeader';

export interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  title?: string | React.ReactNode;
  cancelButtonTitle?: string;
  submitButtonTitle?: string;
  reverseActionsOrder?: boolean;
  width?: string;
  loading?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  hideHeader?: boolean;
  hideFooter?: boolean;
  centerTitle?: boolean;
  buttonRole?: ButtonRole;
  hideCloseButton?: boolean;
  hideSubmitButton?: boolean;
  disabledSubmitButton?: boolean;
  padding?: string;
  scrollLocking?: boolean;
  buttonsShadow?: boolean;
}

const Modal = ({
  open,
  children,
  title,
  cancelButtonTitle,
  submitButtonTitle,
  reverseActionsOrder,
  width = '32.5rem',
  loading,
  onClose,
  onSubmit,
  onCancel,
  hideHeader = false,
  hideFooter = false,
  centerTitle = false,
  buttonRole = ButtonRole.Primary,
  hideCloseButton = false,
  hideSubmitButton = false,
  disabledSubmitButton = false,
  padding,
  scrollLocking = false,
  buttonsShadow = false,
  ...rest
}: ModalProps) => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowDimensions();

  const handleCancelClick = () => {
    onClose?.();
    onCancel?.();
  };

  const Header = () => (
    <ModalHeader centerTitle={centerTitle} title={title} hideCloseButton={hideCloseButton} onClose={onClose} />
  );

  const CancelButton = () => (
    <StyledSecondaryButton onClick={handleCancelClick}>{cancelButtonTitle || t('common.cancel')}</StyledSecondaryButton>
  );
  const SubmitButton = () => (
    <StyledButton disabled={disabledSubmitButton} $buttonRole={buttonRole} loading={loading} onClick={onSubmit}>
      {submitButtonTitle || t('common.ok')}
    </StyledButton>
  );
  const Footer = () => (
    <StyledFooter $reverse={reverseActionsOrder} $shadow={buttonsShadow}>
      <CancelButton />
      {!hideSubmitButton && <SubmitButton />}
    </StyledFooter>
  );

  return (
    <>
      {inMobile(windowWidth) ? (
        <StyledBottomSheet scrollLocking={scrollLocking} open={open} onDismiss={onClose} {...rest}>
          {!hideHeader && <Header />}
          <BodyContainer $padding={padding}>{children}</BodyContainer>
          {!hideFooter && <Footer />}
        </StyledBottomSheet>
      ) : (
        <StyledModal open={open} width={width} onClose={onClose} {...rest}>
          {!hideHeader && <Header />}
          <NextModal.Body>
            <BodyContainer $padding={padding} $disableBottomPadding={buttonsShadow}>
              {children}
            </BodyContainer>
          </NextModal.Body>
          {!hideFooter && <Footer />}
        </StyledModal>
      )}
    </>
  );
};

export default Modal;
