import React, { SVGProps } from 'react';

const LookalikeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.6858 11.3157C7.84497 10.4749 7.97664 9.0299 9.08164 8.37906C9.63914 8.05073 10.3641 8.05073 10.9216 8.37906C12.0258 9.0299 12.1583 10.4749 11.3175 11.3157"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M11.3192 11.3158C10.5925 12.0425 9.41417 12.0425 8.6875 11.3158"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M4.29725 14.8658C1.77725 11.9183 1.90558 7.48496 4.69308 4.69663C7.62225 1.76746 12.3706 1.76746 15.2997 4.69663C18.0881 7.48496 18.2156 11.9191 15.6956 14.8658"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M6.37829 12.7826C4.99412 10.9851 5.10662 8.40591 6.75329 6.75925C8.54329 4.96925 11.445 4.96925 13.235 6.75925C14.8825 8.40675 14.9941 10.9851 13.6091 12.7826"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M6.65625 16.6776C7.08542 15.2301 8.41042 14.1667 9.99792 14.1667C11.5854 14.1667 12.9104 15.2301 13.3396 16.6776C11.2354 17.7309 8.76042 17.7301 6.65625 16.6776Z"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LookalikeIcon;
