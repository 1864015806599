import React from 'react';

import { CloseButton, HeaderContainer, StyledCloseIcon, Title } from './ModalHeader.styles';

interface ModalHeaderProps {
  title?: string | React.ReactNode;
  centerTitle?: boolean;
  hideCloseButton?: boolean;
  onClose?: () => void;
}

const ModalHeader = ({ title, centerTitle = false, hideCloseButton = false, onClose, ...rest }: ModalHeaderProps) => {
  return (
    <HeaderContainer $centerTitle={centerTitle} {...rest}>
      {typeof title === 'string' ? <Title $hasRightPadding={!hideCloseButton && !centerTitle}>{title}</Title> : title}
      {!hideCloseButton && <CloseButton icon={<StyledCloseIcon />} onClick={onClose} />}
    </HeaderContainer>
  );
};

export default ModalHeader;
