import { ApolloProvider } from '@apollo/client';
import { NextUIProvider } from '@nextui-org/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Auth } from 'aws-amplify';
import { AnimatePresence } from 'framer-motion';
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import lazyWithPreload from 'react-lazy-with-preload';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Root } from './App.styles';
import { useBrandContext } from './brands/BrandContext';
import { BlackToast } from './components';
import AuthenticatedRoute from './components/routing/AuthenticatedRoute';
import UnauthenticatedRoute from './components/routing/UnauthenticatedRoute';
import { getHomePath, visitIQAudience, visitIQColumns, visitIQGoogleAnalytics, visitIQSettings } from './constants';
import { AppContext } from './contexts/AppContext';
import { AudienceProvider } from './contexts/AudienceContext';
import { useAuthContext } from './contexts/AuthContext';
import { OrganizationProvider } from './contexts/OrganizationContext';
import { SessionProvider, useSessionContext } from './contexts/SessionContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { TabsProvider } from './contexts/TabsContext';
import { createClient } from './graphql';
import { inMobile } from './hooks/useDimensions';
import LoadingAnimation from './overwrites/LoadingAnimation';
import { GlobalStyles } from './theme/GlobalStyles';
import { nextUIThemeMap } from './theme/Themes';
import { configureAmplify } from './utils/Amplify';
import { configureFirebase } from './utils/Firebase';

configureAmplify();
configureFirebase();

const LoginModule = lazyWithPreload(() => import('./views/auth/LoginPage'));
const SignupModule = lazyWithPreload(() => import('./views/auth/SignupPage'));
const SignupConfirmModule = lazyWithPreload(() => import('./views/auth/SignupConfirmPage'));
const VerifyModule = lazyWithPreload(() => import('./views/auth/VerifyPage'));
const RecoveryMailModule = lazyWithPreload(() => import('./views/auth/RecoveryMailPage'));
const ResetPasswordModule = lazyWithPreload(() => import('./views/auth/ResetPasswordPage'));
const AppContainerModule = lazy(() => import('./views/AppContainer'));

function App(): any {
  const { isBrandChecked, theme } = useBrandContext();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isEnteringForbiddenPage, setIsEnteringForbiddenPage] = useState(false);
  const [wasLoggedIn, setWasLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any | undefined>(undefined);
  const [didMakeInitialAuthAttempt, setDidMakeInitialAuthAttempt] = useState(false);

  const getToastPosition = () => {
    if (inMobile(window.innerWidth)) {
      return 'top-center';
    }
    return 'bottom-right';
  };

  const showError = (error: string) => {
    toast.error(error);
  };

  const showSuccess = (success: string) => {
    toast.success(success);
  };

  const handleError = (error: string, code?: string) => {
    if (code === 'UNAUTHENTICATED') {
      if (isAuthenticated) {
        // trigger a logout
        void reloadAuthInfo();
      } else {
        // don't show a toast if unauthenticated and there's an unauthenticated error
        return;
      }
    }
    if (code === 'FORBIDDEN') {
      setIsEnteringForbiddenPage(true);
      showError(error);
      return;
    }

    showError(error);
  };

  const showAlert = (alert: string) => {
    toast(alert);
  };

  const showBottomToast = (text: string) => {
    toast.custom((currentToast) => <BlackToast toastId={currentToast.id} text={text} />, {
      duration: 3000,
    });
  };

  const { authToken, updateAuthToken } = useAuthContext();

  const preloadUnauthModules = () => {
    void LoginModule.preload();
    void SignupModule.preload();
    void SignupConfirmModule.preload();
    void VerifyModule.preload();
    void RecoveryMailModule.preload();
    void ResetPasswordModule.preload();
  };

  // Keep user-functionalities related keys
  const clearLocalStorage = () => {
    for (const key in localStorage) {
      if (
        !key.startsWith(visitIQColumns) &&
        !key.startsWith(visitIQSettings) &&
        !key.startsWith(visitIQAudience) &&
        !key.startsWith(visitIQGoogleAnalytics)
      ) {
        localStorage.removeItem(key);
      }
    }
  };

  const logout = async () => {
    try {
      await Promise.allSettled([client.clearStore(), Auth.signOut()]);
    } catch (err: any) {
      console.error({
        message: 'Error while logging out',
        error: err?.stack || err,
      });
    }
    clearLocalStorage();
    setWasLoggedIn(true);
    userHasAuthenticated(false);
  };

  const reloadAuthInfo = async (): Promise<void> => {
    setLoading(true);
    const isLoggedIn = await updateAuthToken();
    if (didMakeInitialAuthAttempt || !isLoggedIn) {
      // only hide the loading indicator if the initial auth attempt was already made, or if it didn't and the user
      // is not logged in.. if this is the first auth attempt and the user was indeed logged in, keep the loading
      // indicator some more time until the state changes to authenticated, which right now happens after the
      // "isSwitchingBetweenAuthModes" flag is set.. only when state changes to authenticated we know the unauth UI
      // won't be shown.. below we have an effect that hides the loading indicator when this is the case
      setLoading(false);
    }
    setDidMakeInitialAuthAttempt(true);
    if (!isLoggedIn) {
      preloadUnauthModules();
      if (isAuthenticated) {
        await logout();
      }
    } else if (!isAuthenticated) {
      userHasAuthenticated(isLoggedIn);
    }
  };

  useEffect(() => {
    if (loading && didMakeInitialAuthAttempt && isAuthenticated) {
      // only hide the loading indicator on load once we know the isAuthenticated state has been updated, thus,
      // knowing the authenticated UI will be displayed
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect((): void => {
    void reloadAuthInfo();
  }, []);

  const Main = (): any => {
    const { hasAudienceOnlyAccess, loading: sessionLoading } = useSessionContext();
    // If we're still loading either auth state or session data, show loading
    if (!didMakeInitialAuthAttempt || sessionLoading) {
      return <LoadingAnimation />;
    }

    return isAuthenticated ? (
      <Navigate replace to={getHomePath(hasAudienceOnlyAccess)} />
    ) : (
      <Navigate replace to="/login" />
    );
  };

  const AnimatedRoutes = () => {
    const location = useLocation();

    const contextValues = {
      isAuthenticated,
      reloadAuthInfo,
      showError,
      showAlert,
      showBottomToast,
      user,
      setUser,
      logout,
      location,
      wasLoggedIn,
      showSuccess,
      isEnteringForbiddenPage,
      setIsEnteringForbiddenPage,
    };

    return (
      <AppContext.Provider value={contextValues}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <OrganizationProvider>
            <AudienceProvider>
              <SidebarProvider>
                <TabsProvider>
                  <AnimatePresence initial={false} exitBeforeEnter={true}>
                    <Routes location={location} key={location.pathname}>
                      <Route path="/" element={<Main />} />
                      <Route element={<UnauthenticatedRoute />}>
                        <Route path="/login" element={<LoginModule />} />
                        <Route path="/signup" element={<SignupModule />} />
                        <Route path="/signup-confirm" element={<SignupConfirmModule />} />
                        <Route path="/verify" element={<VerifyModule />} />
                        <Route path="/recovery-mail" element={<RecoveryMailModule />} />
                        <Route path="/reset-password" element={<ResetPasswordModule />} />
                      </Route>
                      <Route element={<AuthenticatedRoute />}>
                        <Route path="/app/*" element={<AppContainerModule />} />
                      </Route>
                      <Route path="*" element={<Navigate replace to="/app/" />} />
                    </Routes>
                  </AnimatePresence>
                </TabsProvider>
              </SidebarProvider>
            </AudienceProvider>
          </OrganizationProvider>
        </GoogleOAuthProvider>
      </AppContext.Provider>
    );
  };

  const client = useMemo(() => createClient(authToken, handleError), [authToken]);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <NextUIProvider theme={nextUIThemeMap(theme)}>
          <>
            <GlobalStyles theme={theme} />
            {!isBrandChecked || loading ? (
              <LoadingAnimation />
            ) : (
              <SessionProvider>
                <Router>
                  <Suspense fallback={<LoadingAnimation />}>
                    <Root>
                      <AnimatedRoutes />
                    </Root>
                  </Suspense>
                </Router>
              </SessionProvider>
            )}
          </>
          <Toaster
            containerStyle={{
              zIndex: 10000,
              bottom: inMobile(window.innerWidth) ? 32 : 80,
              right: inMobile(window.innerWidth) ? 16 : 40,
            }}
            position={getToastPosition()}
          />
        </NextUIProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
