import { Collapse, Divider } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

const height = '4rem';

export const StyledCollapse = styled(Collapse)`
  background-color: white;
  border-radius: 6px;
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c050};
  margin: 0.5rem 0;
  padding: 0rem 1rem;

  [role='button'] {
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .nextui-collapse-title-content {
    display: none;
  }

  .nextui-collapse-title-content-left {
    flex: 1;
    overflow: hidden;
  }

  .nextui-collapse-title-content-right {
    width: unset;
    flex: unset;
  }

  .nextui-collapse-view[aria-expanded='true'] {
    .nextui-collapse-title-container {
      .nextui-collapse-title-content-right {
        svg {
          transform: rotateZ(-180deg);
        }
      }
    }
  }
`;

export const StyledCollapseTitle = styled.div`
  align-items: center;
  display: flex;
  height: ${height};
  justify-content: space-between;
  width: 100%;
`;

export const ChevronContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${height};
  justify-content: center;
  margin-left: 0.5rem;
  width: 1.5rem;
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
`;

export const StyledDivider = styled(Divider)`
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c050};
`;
