import { Dropdown as NextDropdown } from '@nextui-org/react';
import React, { Key, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ChevronIcon } from '~/assets';
import { ShimmerImage, ShimmerText } from '~/components';
import { DropDownProps } from '~/components/Dropdown/Dropdown';
import {
  Container,
  DropdownError,
  DropDownItemContainer,
  DropDownItemDescription,
  DropDownItemLeftContainer,
  DropDownItemLoader,
  DropDownItemText,
  Separator,
  Title,
} from '~/components/Dropdown/Dropdown.styles';
import Badge from '~/components/FilterBadge';
import { useDebounce } from '~/hooks/useDebounce';

import { parsePx } from '../../hooks/useDimensions';
import useDropdown from '../Dropdown/useDropdown';
import {
  NoResults,
  StyledDropdown,
  StyledDropDownButton,
  StyledDropDownMenu,
  StyledInput,
  StyledSearchIcon,
} from './InfiniteScrollDropdown.styles';

const KeyNoResults = 'noResults';
const scrollViewId = 'scrollableDiv';

export type InfiniteScrollDropdownSelection = number | number[];

export interface InfiniteScrollProps {
  hasMore: boolean;
  loadMore: () => void;
  searchOrgInfiniteScroll: string;
  setSearchOrgInfiniteScroll: (value: string) => void;
  loadingFirst: boolean;
  loadingMore: boolean;
}

export interface InfiniteScrollDropdownProps<T = any> extends DropDownProps<T> {
  prefilledValue?: string;
  shouldApplyRoundCorners?: boolean;
}

// eslint-disable-next-line comma-spacing
const InfiniteScrollDropdown = <T,>(props: InfiniteScrollDropdownProps<T> & InfiniteScrollProps) => {
  const {
    title,
    required,
    items,
    disabledKeys,
    disallowEmptySelection,
    selectedIndex,
    placeholder,
    error,
    onSelectionChange,
    prefilledValue,
    placeholderIcon,
    showBadge,
    hasMore,
    loadMore,
    searchOrgInfiniteScroll,
    setSearchOrgInfiniteScroll,
    loadingFirst,
    loadingMore,
    shouldApplyRoundCorners = false,
    showSelectedValue,
    disabled,
    ...rest
  } = props;
  const [searchTextLocal, setSearchTextLocal] = useState(searchOrgInfiniteScroll || '');
  const searchTextDebounce = useDebounce(searchTextLocal, 500) as string;
  const { t } = useTranslation();

  const [maxHeight, setMaxHeight] = useState('20rem');
  const {
    selected,
    setSelected,
    selectedValue: selectedValueDropDown,
    selectedIcon,
    disabledKeysLocal,
    buttonRef,
    buttonWidth,
  } = useDropdown(props);

  useEffect(() => {
    setSearchOrgInfiniteScroll?.(searchTextDebounce);
  }, [searchTextDebounce]);

  // The prefilled value is used to display the value of the dropdown when the user filtered
  // in the orgs page but we eventually don't have the item loaded.
  const selectedValue = useMemo(() => selectedValueDropDown || prefilledValue, [selectedValueDropDown, prefilledValue]);

  const onLocalSelectionChange = (keys: 'all' | Set<Key>) => {
    if (keys === 'all' || (keys?.size === 1 && Array.from(keys)?.[0] === KeyNoResults)) return;
    setSelected(keys);
    const selectedIndexes = Array.from(keys || []).map((key) => Number(key));
    onSelectionChange?.(selectedIndexes[0]);
  };

  const listShimmer = [...Array(10)].map((_, index) => (
    <NextDropdown.Item key={`loader-${index.toString()}`}>
      <DropDownItemLoader>
        <ShimmerImage width="1.5rem" height="1.5rem" />
        <DropDownItemText>
          <ShimmerText width="7rem" />
        </DropDownItemText>
      </DropDownItemLoader>
    </NextDropdown.Item>
  ));

  const recalculateMaxHeight = () => {
    // get the height of the element with class name 'nextui-popover-content'
    const element = document.getElementsByClassName('nextui-popover-content-container')[0] as HTMLElement;

    if (!element) return;

    const height = parsePx(element.style.maxHeight);

    // We need to be able to trigger pagination
    let maxHeight = height / 16 - 3.25;
    if (maxHeight > 21) maxHeight = 21;

    setMaxHeight(`${maxHeight}rem`);
  };

  useEffect(() => {
    recalculateMaxHeight();

    // Listen for changes in the popover and recalculate the max height
    window.addEventListener('resize', recalculateMaxHeight);
    window.addEventListener('scroll', recalculateMaxHeight);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', recalculateMaxHeight);
      window.removeEventListener('scroll', recalculateMaxHeight);
    };
  }, []);

  const dropDownComponent = useMemo(
    () => (
      <StyledDropDownMenu
        width={buttonWidth}
        $maxHeight={maxHeight}
        selectionMode="single"
        disallowEmptySelection={disallowEmptySelection}
        selectedKeys={selected}
        disabledKeys={disabledKeysLocal}
        onSelectionChange={onLocalSelectionChange}
        id={scrollViewId}
      >
        {items
          .map((item, index) => (
            <NextDropdown.Item key={index.toString()} textValue={index.toString()}>
              <DropDownItemContainer>
                <DropDownItemLeftContainer>
                  {item.icon}
                  <DropDownItemText>{item.text}</DropDownItemText>
                </DropDownItemLeftContainer>
                {item.description && <DropDownItemDescription>{item.description}</DropDownItemDescription>}
              </DropDownItemContainer>
              {index !== items.length - 1 && <Separator />}
            </NextDropdown.Item>
          ))
          .concat(
            loadingFirst || loadingMore ? (
              listShimmer
            ) : !items.length ? (
              <NextDropdown.Item key={KeyNoResults}>
                <NoResults>{t('common.noResults')}</NoResults>
              </NextDropdown.Item>
            ) : null,
          )}
      </StyledDropDownMenu>
    ),
    [buttonWidth, items, onLocalSelectionChange, selected, selectedValue, disabledKeys, disallowEmptySelection],
  );

  const infiniteScrollComponent = useMemo(() => {
    return (
      <>
        <StyledInput
          placeholder={searchTextLocal}
          aria-label={t('common.search')}
          iconLeft={<StyledSearchIcon />}
          clearable
          onChange={(e: any) => setSearchTextLocal(e.target.value as string)}
          value={searchTextLocal}
          removeMargin
          containerMarginTop="0.75rem"
          marginBottom="0.25rem"
        />
        <InfiniteScroll
          dataLength={items.length}
          next={() => loadMore()}
          hasMore={hasMore}
          hasChildren
          scrollThreshold="20px"
          scrollableTarget={scrollViewId}
          loader={null}
        >
          {dropDownComponent}
        </InfiniteScroll>
      </>
    );
  }, [items, hasMore, searchTextLocal, dropDownComponent]);

  return (
    <Container {...rest}>
      {title && (
        <Title>
          {title}
          {required && ' *'}
        </Title>
      )}
      <StyledDropdown
        isBordered
        onOpenChange={(isOpen) => {
          if (isOpen) {
            setTimeout(() => {
              recalculateMaxHeight();
              // Wait for the dropdown to open
            }, 100);
          }
        }}
      >
        <StyledDropDownButton
          disabled={disabled}
          shouldApplyRoundCorners={shouldApplyRoundCorners}
          isBordered
          ref={buttonRef}
          iconRight={<ChevronIcon />}
          error={error}
        >
          <DropDownItemLeftContainer>
            {selectedValue && (showSelectedValue || !showBadge) ? (
              <>
                {selectedIcon}
                {selectedValue}
              </>
            ) : (
              <>
                {placeholderIcon}
                {placeholder || ''}
              </>
            )}
            {showBadge && <Badge>1</Badge>}
          </DropDownItemLeftContainer>
        </StyledDropDownButton>

        {infiniteScrollComponent}
      </StyledDropdown>
      {error && <DropdownError>{error}</DropdownError>}
    </Container>
  );
};

export default InfiniteScrollDropdown;
