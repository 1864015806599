import styled from 'styled-components';

import { ThemeProp } from '../../../../theme/Themes';

interface DisabledProps {
  $disabled?: boolean;
}

const getOpacity = (props: DisabledProps) => (props.$disabled ? 0.5 : 1);

export const CardTitle = styled.div<DisabledProps>`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.m};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.m};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  opacity: ${(props: DisabledProps) => getOpacity(props)};
`;

export const CardDescription = styled.span<DisabledProps>`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  opacity: ${(props: DisabledProps) => getOpacity(props)};
  flex: 1;
`;

export const ComingSoonBadge = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background-color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  padding: 0.375rem 0.5rem;
  border-radius: 0.1875rem;
  z-index: 2;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 1rem;
  gap: 0.5rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    gap: 0;
  }
`;
export const Badge = styled.div`
  background-color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  height: 1.25rem;
  border-radius: 0.1875rem;
  width: fit-content;
  padding: 0 0.375rem;
`;

export const BackgroundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 7.25rem;
    height: 7.25rem;
  }

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    svg {
      width: 5rem;
      height: 5rem;
    }
  }
`;
