import { CheckboxProps } from '@nextui-org/react';
import React, { ChangeEvent, forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';

import AudiencesDropdown from '~/components/AudiencesDropdown';
import { AudiencesDropdownProps } from '~/components/AudiencesDropdown/AudiencesDropdown';

import { Input, MultiEmailInput, Switch } from '../';
import AvatarUploader, { AvatarUploaderProps } from '../AvatarUploader/AvatarUploader';
import { DropDownProps } from '../Dropdown/Dropdown';
import { InputProps } from '../Input/Input';
import OrganizationsDropdown, { OrganizationsDropdownProps } from '../OrganizationsDropdown/OrganizationsDropdown';
import PhoneInput, { PhoneInputProps } from '../PhoneInput/PhoneInput';
import PlacesAutocomplete, { PlacesAutocompleteProps } from '../PlacesAutocomplete/PlacesAutocomplete';
import { StyledCheckbox, StyledDropdown } from './Form.styles';

interface CommonFormFieldProps {
  dataKey: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  errorRequired?: string;
}

export type InputFormProps = CommonFormFieldProps & InputProps;
export type SwitchFormProps = CommonFormFieldProps;
export type DropdownFormProps = CommonFormFieldProps & DropDownProps;
type PlacesFormProps = CommonFormFieldProps & PlacesAutocompleteProps;
interface AvatarFormProps extends CommonFormFieldProps, Omit<AvatarUploaderProps, 'onUploadFinish'> {
  onUploadFinish?: (url: string) => void;
}
type PhoneFormProp = CommonFormFieldProps & PhoneInputProps;
type OrganizationsFormProps = CommonFormFieldProps & OrganizationsDropdownProps;
type AudiencesFormProps = CommonFormFieldProps & AudiencesDropdownProps;
type CheckboxFormProps = CommonFormFieldProps & CheckboxProps;

type FormRenderType = {
  input: (props: InputFormProps) => ReactNode;
  dropdown: (props: DropdownFormProps) => ReactNode;
  places: (props: PlacesFormProps) => ReactNode;
  avatar: (props: AvatarFormProps) => ReactNode;
  phone: (props: PhoneFormProp) => ReactNode;
  multiEmailInput: (props: InputFormProps) => ReactNode;
  organizations: (props: OrganizationsFormProps) => ReactNode;
  audiences: (props: AudiencesFormProps) => ReactNode;
  switchComponent: (props: SwitchFormProps) => ReactNode;
  data: FormData;
  isDataChanged: () => boolean;
  isRequiredDataFilled: boolean;
  checkbox: (props: CheckboxFormProps) => ReactNode;
};

export type FormData = Record<string, any>;
type ErrorsData = Record<string, string>;

interface FormProps {
  data?: FormData;
  validateFn?: (formData: FormData, errors: ErrorsData) => boolean;
  onSubmit?: (formData: FormData) => void;
  children: (renderProps: FormRenderType) => ReactNode;
}

const Form = forwardRef(({ children, data, validateFn, onSubmit }: FormProps, ref) => {
  const [localData, setLocalData] = useState<FormData>(data || {});
  const [initialData, setInitialData] = useState<FormData>(data || {});
  const [errors, setErrors] = useState<ErrorsData>({});
  const [isRequiredDataFilled, setIsRequiredDataFilled] = useState<boolean>(false);
  const [cancelCounter, setCancelCounter] = useState(0);
  const fields: CommonFormFieldProps[] = [];

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit();
    },
    cancel: () => {
      setLocalData(data || {});
      setErrors({});
      setCancelCounter(cancelCounter + 1);
    },
  }));

  const isDataChanged = () => {
    for (const key in data) {
      if (data[key] !== localData[key]) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    const fieldsRequired = fields.filter((item) => item.required);
    for (const field of fieldsRequired) {
      const key = field.dataKey;
      if (localData[key] === undefined || localData[key] === '') {
        return setIsRequiredDataFilled(false);
      }
    }
    return setIsRequiredDataFilled(true);
  }, [fields, localData]);

  const updateLocalDataIfNeeded = () => {
    let isChanged = false;
    for (const key in data) {
      if (data[key] !== initialData[key]) {
        localData[key] = data[key];
        isChanged = true;
      }
    }

    if (isChanged) {
      setInitialData(data);
    }
  };

  updateLocalDataIfNeeded();

  const validateRequiredFields = () => {
    const errorsRequired = {};
    const fieldsRequired = fields.filter((item) => item.required);
    for (const field of fieldsRequired) {
      const key = field.dataKey;
      if (localData[key] === undefined || localData[key] === '') {
        errorsRequired[key] = field.errorRequired;
      }
    }

    return errorsRequired;
  };

  const validate = () => {
    const localErrors = validateRequiredFields();
    if (Object.keys(localErrors).length > 0) {
      setErrors(localErrors);
      return false;
    }

    if (validateFn) {
      const valid = validateFn(localData, localErrors);
      setErrors(localErrors);
      return valid;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }

    if (onSubmit) onSubmit(localData);
  };

  const updateValue = (key: string, value: any) => {
    setLocalData({ ...localData, [key]: value });
    setErrors({ ...errors, [key]: undefined });
  };

  const addFormFieldIfNeeded = (props: CommonFormFieldProps) => {
    if (fields.some((item) => item.dataKey === props.dataKey)) return;
    fields.push(props);
  };

  const input = (props: InputFormProps) => {
    addFormFieldIfNeeded(props);

    const { dataKey, ...rest } = props;
    const InputComponent = props.type === 'password' ? Input.Password : Input;
    return (
      <InputComponent
        key={dataKey}
        fullWidth
        value={localData[dataKey] || ''}
        error={errors[dataKey]}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          updateValue(dataKey, e.target.value.trim());
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleSubmit();
          }
        }}
        {...rest}
      />
    );
  };

  const checkbox = (props: CheckboxFormProps) => {
    addFormFieldIfNeeded(props);

    const { dataKey, label, ...rest } = props;
    return (
      <StyledCheckbox
        key={dataKey}
        isSelected={localData[dataKey] || false}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          updateValue(dataKey, e);
        }}
        {...rest}
      >
        {label}
      </StyledCheckbox>
    );
  };

  const dropdown = (props: DropdownFormProps) => {
    addFormFieldIfNeeded(props);

    const { dataKey, label, onSelectionChange, ...rest } = props;
    return (
      <StyledDropdown
        key={dataKey}
        title={label}
        error={errors[dataKey]}
        selectedIndex={props.selectedIndex >= 0 ? props.selectedIndex : localData[dataKey]}
        onSelectionChange={(selection) => {
          updateValue(dataKey, selection);
          onSelectionChange?.(selection);
        }}
        {...rest}
      />
    );
  };

  const multiEmailInput = (props: InputFormProps) => {
    addFormFieldIfNeeded(props);
    const { dataKey, onSelect, label, ...rest } = props;

    const handleEmailsChange = (_emails: string[]) => {
      if (_emails.length === 0) {
        updateValue(dataKey, undefined);
      } else {
        updateValue(dataKey, _emails);
      }
    };

    return (
      <MultiEmailInput
        key={dataKey}
        emails={localData[dataKey]}
        onChange={(_emails: string[]) => {
          handleEmailsChange(_emails);
        }}
        label={label}
        error={errors[dataKey]}
        onRemove={(_emails: string[]) => {
          handleEmailsChange(_emails);
        }}
        {...rest}
      />
    );
  };

  const places = (props: PlacesFormProps) => {
    addFormFieldIfNeeded(props);

    const { dataKey, onSelect, ...rest } = props;
    return (
      <PlacesAutocomplete
        key={`places-${dataKey}-${cancelCounter}`}
        error={errors[dataKey]}
        onSelect={(place) => {
          updateValue(dataKey, place);
          onSelect?.(place);
        }}
        {...rest}
      />
    );
  };

  const avatar = (props: AvatarFormProps) => {
    addFormFieldIfNeeded(props);

    const { dataKey, onUploadFinish, ...rest } = props;
    return (
      <AvatarUploader
        key={dataKey}
        imageUrl={localData[dataKey]}
        onUploadFinish={(url: string) => {
          updateValue(dataKey, url);
          onUploadFinish?.(url);
        }}
        {...rest}
      />
    );
  };

  const switchComponent = (props: SwitchFormProps) => {
    addFormFieldIfNeeded(props);
    const { dataKey, ...rest } = props;
    const value = localData[dataKey];

    return (
      <Switch
        size={'xs'}
        title={props.label}
        checked={value}
        disabled={props.disabled}
        {...rest}
        helpText={''}
        onChange={() => updateValue(dataKey, !value)}
      />
    );
  };

  const phone = (props: PhoneFormProp) => {
    addFormFieldIfNeeded(props);

    const { dataKey, defaultValue, ...rest } = props;
    const value = localData[dataKey] as string;
    return (
      <PhoneInput
        key={`phone-${dataKey}-${cancelCounter}`}
        error={errors[dataKey]}
        value={parsePhoneNumber(value || '')?.number}
        defaultValue={parsePhoneNumber(defaultValue || '')?.nationalNumber || ''}
        onChange={(phone, isComplete) => {
          updateValue(dataKey, isComplete ? phone : '');
        }}
        {...rest}
      />
    );
  };

  const organizations = (props: OrganizationsFormProps) => {
    addFormFieldIfNeeded(props);

    const { dataKey, label, onSelectionChange, ...rest } = props;
    return (
      <OrganizationsDropdown
        key={dataKey}
        title={label}
        error={errors[dataKey]}
        selectedIndex={props.selectedIndex >= 0 ? props.selectedIndex : undefined}
        onSelectionChange={(selection, org) => {
          updateValue(dataKey, org);
          onSelectionChange?.(selection, org);
        }}
        {...rest}
      />
    );
  };

  const audiences = (props: AudiencesFormProps) => {
    addFormFieldIfNeeded(props);

    const { dataKey, label, onSelectionChange, ...rest } = props;
    return (
      <AudiencesDropdown
        key={dataKey}
        title={label}
        error={errors[dataKey]}
        selectedIndex={props.selectedIndex >= 0 ? props.selectedIndex : undefined}
        onSelectionChange={(selection, org) => {
          updateValue(dataKey, org);
          onSelectionChange?.(selection, org);
        }}
        {...rest}
      />
    );
  };

  return (
    <>
      {children({
        input,
        dropdown,
        places,
        avatar,
        phone,
        data: localData,
        isDataChanged,
        isRequiredDataFilled,
        multiEmailInput,
        organizations,
        switchComponent,
        checkbox,
        audiences,
      })}
    </>
  );
});

export default Form;
