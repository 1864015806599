import { Dropdown, DropdownMenuProps } from '@nextui-org/react';
import styled from 'styled-components';

import { SearchIcon } from '~/assets';
import { DropDownButton, DropDownMenu } from '~/components/Dropdown/Dropdown.styles';
import Input from '~/components/Input';
import { ThemeProp } from '~/theme/Themes';

export const StyledDropdown = styled(Dropdown)`
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const StyledDropDownButton = styled(DropDownButton).attrs((props) => {
  return {
    css: {
      ...props.css,
      height: '2rem',
      width: 'fit-content',
      'border-radius': props.shouldApplyRoundCorners ? '2rem' : props.theme.borderRadius,
    },
  };
})``;

interface StyledDropDownMenuProps extends DropdownMenuProps {
  $maxHeight?: string;
}
export const StyledDropDownMenu = styled(DropDownMenu)`
  overflow-y: auto;
  max-height: ${(props: StyledDropDownMenuProps) => props.$maxHeight || '20rem'};
  width: 25rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    width: 22rem;
  }

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.smallMobile}) {
    width: 17rem;
  }
`;

export const StyledInput = styled(Input)`
  height: 2rem !important;
  width: calc(100% - 1.5rem);
  margin-bottom: 0 !important;
  margin: 0 0.75rem;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
`;

export const NoResults = styled.span`
  margin: 0.125rem 1rem;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  display: flex;
  align-items: center;
  height: 2.5rem;
`;
